import { useCallback, useEffect, useState } from "react";
import { IconButton, Modal, Typography } from "@mui/material";
import {
  useGetUserGuideQuery,
  useGetUserGuideQuestionsQuery,
  useGetUserGuideLinkQuery,
} from "../../app/api";
const UserGuideButton = ({ showIcon }) => {
  const { data, isLoading } = useGetUserGuideQuery({ accounttype: 0 });
  const { data: allQuest } = useGetUserGuideQuestionsQuery();

  console.log("theese are thee questions", allQuest);
  const [query, setQuery] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [list, setList] = useState([]);
  const [arr, setArr] = useState([]);
  const [isQid, setQid] = useState();
  let _obj = { id: "", data: [] };
  const [obj, setObj] = useState(_obj);
  const { data: links, refetch } = useGetUserGuideLinkQuery({
    accountType: 0,
    qid: isQid,
  });
  useEffect(
    (id) => {
      if (data) {
        setArr(data?.data);
        setList(data?.data);
      }
    },
    [data]
  );

  const getLink = useCallback(
    (id) => {
      setQid(id);
      refetch();
      if (Array.isArray(data.data) && data.data.length > 0) {
        setObj({ ...obj, id, data: data.data });
      }
    },
    [obj, data, isQid]
  );
  const searchFilter = (e) => {
    let filterArr = [];
    const queryRegex = new RegExp(e.target.value, "i");
    arr.forEach((item) => {
      Object.values(item).some((val) =>
        queryRegex.test(val) ? filterArr.push(item) : ""
      );
    });

    setList(filterArr);
  };

  const content = (
    <>
      <div className="lg:w-[60%] w-[90%] rounded-[20px] h-[600px] bg-white overflow-auto pb-[30px]">
        <div
          style={{
            textAlign: "center",
            fontWeight: "bold",
            background: "#671E78",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            padding: "1.4rem",
            color: "white",
            display: "flex",
            justifyContent: "space-between",
          }}
          className="items-center"
        >
          <span></span> <p>User Guides</p>{" "}
          <button
            onClick={() => setShowModal(false)}
            className="border border-white rounded-[50px] text-[14px] p-2"
          >
            X
          </button>
        </div>
        <div style={{ padding: "0.8rem" }}>
          <input
            type="text"
            value={query}
            placeholder="Search Topic"
            className="w-full border border-primary focus:outline-none md:p-3 p-2 rounded-[10px]"
            onChange={(e) => {
              setQuery(e.target.value);
              if (e.target.value.length > 2) {
                searchFilter(e);
              }

              if (e.target.value === "") {
                setList(arr);
                console.log("hellow world inside jsx");
              }
            }}
          />

          <div style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}>
            {list.map((item, index) => (
              <div key={index}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderRadius: "10px",
                    margin: "0.4rem 0rem",
                    alignItems: "center",
                    padding: "0.5rem 0.8rem",
                  }}
                  className="bg-[#671E78]/50"
                >
                  <p className="md:font-bold font-normal ">{item?.topic}</p>
                  {obj && obj.id === item.qid && obj.data.length > 0 ? (
                    <button
                      onClick={() => setObj(Object.assign({}, _obj))}
                      style={{
                        borderRadius: "15px",
                        color: "#671E78",
                        border: `1px solid #671E78`,
                        backgroundColor: "transparent",
                        padding: "0.3rem 0.9rem",
                        fontWeight: "bold",
                      }}
                    >
                      Cancel
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        getLink(item.qid);
                        refetch();
                      }}
                      style={{
                        borderRadius: "15px",
                        color: "#671E78",
                        border: `1px solid #671E78`,
                        backgroundColor: "transparent",
                        padding: "0.3rem 0.9rem",
                        fontWeight: "bold",
                      }}
                    >
                      View
                    </button>
                  )}
                </div>
                {obj &&
                  obj.id === item.qid &&
                  obj.data.length > 0 &&
                  links.data.map((i, ind) => (
                    <div
                      key={ind}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "0.3rem",
                        padding: "0rem 0.9rem",
                      }}
                    >
                      <p>Step {i.steps}</p>
                      <a
                        href={i.link}
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          borderRadius: "10px",
                          color: "#671E78",
                          border: `1px solid #671E78`,
                          backgroundColor: "transparent",
                          padding: "0.3rem 0.9rem",
                          fontWeight: "bold",
                        }}
                      >
                        Doc Link
                      </a>
                      {/* </div> */}
                    </div>
                  ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );

  return (
    <>
      {showModal && (
        <Modal
          open={showModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          onClose={() => setShowModal(false)}
          className="w-full h-full "
        >
          <div className="flex justify-center items-center top-[40px]  relative ">
            {content}
          </div>
        </Modal>
      )}

      <IconButton
        className="  shadow-lg cursor-pointer"
        onClick={() => setShowModal(true)}
      >
        <div
          style={{
            padding: "8px",
            background: "#671E781a",
            borderRadius: "50%",
          }}
        >
          <div className="md:h-[40px]md:w-[40px] h-[25px] w-[25px] rounded-full flex justify-center items-center bg-[#671E78]">
            <h1
              style={{
                color: "#ffffff",
                fontSize: "18px",
              }}
            >
              !
            </h1>
          </div>
        </div>
      </IconButton>
    </>
  );
};

export default UserGuideButton;
