import React, { useState } from "react";
import { IoIosArrowDropdown } from "react-icons/io";
import { Typography } from "../../components/Typography";
import { Button } from "../../components";
import { useDispatch } from "react-redux";
import { setToggleModal } from "../../app/slice";
import { setActive } from "./slice";
import { CiCircleChevDown, CiCircleChevUp } from "react-icons/ci";
const Enroll = ({ props, active }) => {
  const [isLoading, setIsLoading] = useState(false);
  // const [selectedValue, setSelectedValue] = useState(false);
  const icons = {
    color: "#fff",
  };
  const dispatch = useDispatch();
  const handleClick = () => {
    setIsLoading(true);
    dispatch(setToggleModal(true));
    const interval = setInterval(() => {
      // Do some async operation
      clearInterval(interval);
      setIsLoading(false);
    }, 1000);
  };

  return (
    <div>
      <div
        onClick={() => {
          dispatch(setActive(active === 1 ? null : 1));
        }}
        className="flex items-center border-b-[0.3px] border-solid border-white w-4/5 mr-auto ml-auto cursor-pointer"
      >
        <div className="w-2.5 h-2.5 rounded-full bg-[#fff] mr-4"></div>
        <div className=" flex-1">
          <Typography className="text-[#fff] text-2xl md:text-3xl">
            Admission
          </Typography>
        </div>
        <div className="">
        {active === 1 ? (
            <CiCircleChevDown {...icons} className="w-7 h-7 mb-[0.2rem]" />
          ) : (
            <CiCircleChevUp {...icons} className="w-7 h-7 mb-[0.2rem]" />
          )}
        </div>
      </div>
      <div
        className={
          active === 1
            ? "grid place-items-center justify-center p-10 md:w-4/5 w-[100%] lg:w-4/5 mr-auto ml-auto mt-5  md:mt-8 md:flex-row"
            : "hidden"
        }
      >
        <div className=" md:flex-1 mb-5 md:mb-0">
          <Button
            {...props}
            isLoading={isLoading}
            onClick={handleClick}
            className="text-primary bg-white p-6 md:p-9  md:w-auto"
          >
            Start Admission
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Enroll;
