import React from "react";
import ReactDOM from "react-dom/client";
import "../src/components/card/card.css";
import "./index.css";
import Home from "./screens/home/Home";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./app/store";
import "./components/header/header.css";
import Header from "./components/header/Header";
import Values from "./screens/vlues/Values";
import MainSections from "./layouts/mainSections/MainSections";
import BoardMembers from "./layouts/boardMembers/BoardMembers";
import Questions from "./layouts/questions/Questions";
import Footer from "./components/footer/Footer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SlideNews } from "./components/slideNews";
import { ThemeProvider } from "@material-tailwind/react";
import UserGuideButton from "./components/userGuide/index";
// import { UserGuidButton } from "./components/userGuide/userGuideButton";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider>
        <Header />
        <Home />
        <Values />
        <SlideNews />
        <MainSections />
        <BoardMembers />
        <Questions />
        <Footer />
        <div className="fixed bottom-[50px] left-[70%] md:left-[90%]">
          <UserGuideButton />
        </div>
      </ThemeProvider>
    </Provider>
    <ToastContainer />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
