import { useEffect, useRef, useState } from "react";
import { Typography } from "../components";

export const useCountdown = (props) => {
  const {
    initialHour = 0,
    initialMinute = 0,
    initialSeconds = 0,
    onComplete,
    isStart,
  } = props;

  const [hours, setHours] = useState(initialHour);
  const [minutes, setMinutes] = useState(initialMinute);
  const [seconds, setSeconds] = useState(initialSeconds);

  const onCompleted = () => {
    onComplete?.();
  };

  // console.log(minutes,":",seconds)

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0 && isStart) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0 && isStart) {
        if (minutes === 0) {
          if (hours === 0) {
            clearInterval(myInterval);
          } else {
            setHours(hours - 1);
            setMinutes(59);
            setSeconds(59);
          }
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }

      if (seconds === 0 && minutes === 0 && hours === 0) {
        onCompleted();
      }
    }, 1000);

    return () => {
      clearInterval(myInterval);
    };
  });

  const reset = () => {
    setHours(initialHour);
    setMinutes(initialMinute);
    setSeconds(initialSeconds);
  };

  const Timer = () => (
    <>
      {minutes === 0 && seconds === 0 ? null : (
        <Typography className="inline">
          {hours === 0 ? null : `${hours}:`}
          {minutes < 10 ? `0${minutes}` : minutes}:
          {seconds < 10 ? `0${seconds}` : seconds}
        </Typography>
      )}
    </>
  );

  return { reset, Timer };
};
