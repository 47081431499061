import { createApi } from "@reduxjs/toolkit/query/react";
import { API } from "../utils/constants";
import { baseQuery } from "./baseQuery";

export const appApi = createApi({
  baseQuery,
  reducerPath: "appApi",
  endpoints: (build) => ({
    getSchools: build.query({
      query: () => API.GET_SCHOOLS,
    }),

    getSchoolProfile: build.query({
      query: ({ id }) => `${API.GET_SCHOOL_PROFILE}/${id}`,
    }),

    getAboutSchool: build.query({
      query: ({ id }) => `${API.GET_ABOUT_SCHOOL}/${id}`,
    }),

    getSchoolContact: build.query({
      query: ({ id }) => ({
        url: `${API.GET_SCHOOL_CONTACT}/${id}`,
        method: "GET",
      }),
    }),
    getContacts: build.query({
      query: ({ id }) => ({
        url: `${API.GET_CONTACTS}/${id}`,
        method: "GET",
      }),
    }),
    getSchoolBoard: build.query({
      query: ({ id }) => API.GET_BOARD_TEAM,
    }),

    getFaqs: build.query({
      query: ({ id }) => ({
        url: `${API.GET_SCHOOL_FAQ}/${id}`,
        method: "GET",
      }),
    }),

    getSchoolTeam: build.query({
      query: ({ id }) => `${API.GET_SCHOOL_TEAM}/${id}`,
    }),

    getLasmabInfo: build.query({
      query: (data) => ({
        url: `${API.GET_INFO}`,
      }),
    }),

    getBoard: build.query({
      query: () => API.GET_BOARD,
    }),

    sendAuthenticationCode: build.mutation({
      query: ({ phoneNumber, email, countryCode, accountType = 2 }) => ({
        url: API.AUTH_CODE,
        method: "POST",
        body: { phoneNumber, email, countryCode, accountType },
      }),
    }),

    verifyAuthCode: build.mutation({
      query: ({ phoneNumber, code, accountType = 2 }) => ({
        url: API.AUTH_VERIFY_CODE,
        method: "POST",
        body: { phoneNumber, code, accountType },
      }),
    }),

    createPin: build.mutation({
      query: ({ phoneNumber, pin, browserToken, accountType = 0 }) => ({
        url: API.Auth_CREATE_PIN,
        method: "POST",
        body: { phoneNumber, pin, browserToken, accountType },
      }),
    }),

    createComplaint: build.mutation({
      query: ({ ...values }) => ({
        url: API.COMPLAINT,
        method: "POST",
        body: { ...values },
      }),
    }),
    getUserGuideQuestions: build.query({
      query: () => ({
        url: API.GET_ALL_GUIDE_QUESTIONS,
        method: "GET",
      }),
    }),
    getUserGuide: build.query({
      query: ({ accounttype }) => ({
        url: API.GET_USER_GUIDE,
        mathod: "GET",
      }),
    }),
    getUserGuideLink: build.query({
      query: ({ accountType, qid }) => ({
        url: API.GET_USER_GUIDE_LINK,
        method: "GET",
        params: { accountType, qid },
      }),
    }),
  }),
});

export const selectSchoolProfile = (state) =>
  appApi.endpoints.getSchoolProfile.select()(state);
export const selectAboutSchool = (state) =>
  appApi.endpoints.getAboutSchool.select()(state);

export const {
  useLazyGetSchoolProfileQuery,
  useGetSchoolsQuery,
  useGetAboutSchoolQuery,
  useLazyGetAboutSchoolQuery,
  useGetSchoolBoardQuery,
  useGetFaqsQuery,
  useGetSchoolContactQuery,
  useGetSchoolTeamQuery,
  useGetBoardQuery,
  useSendAuthenticationCodeMutation,
  useVerifyAuthCodeMutation,
  useCreatePinMutation,
  useCreateComplaintMutation,
  useGetLasmabInfoQuery,
  useGetContactsQuery,
  useGetUserGuideQuery,
  useGetUserGuideQuestionsQuery,
  useGetUserGuideLinkQuery,
} = appApi;
