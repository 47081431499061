import React from "react";
import QuickView from "../../components/QuickView";
import Carousel from "../carousel/Carousel";
import AboutSchool from "../dropdowns/AboutSchool";
import Portal from "../dropdowns/Portal";
import Enroll from "../dropdowns/Enroll";
import Team from "../../layouts/dropdowns/Team";
import Contact from "../../layouts/dropdowns/Contact";
import FAQs from "../dropdowns/FAQs";
import { useSelector } from "react-redux";
import { selectActiveAccordion } from "../dropdowns/slice";

const Mainsections = () => {

  const isActive = useSelector(selectActiveAccordion);

  return (
    <div
      id="enrollment"
      className="bg-primary w-full py-20 md:p-[5%] place-content-center justify-center"
      style={{ color: "#006600" }}
    >
      <QuickView />
      <Carousel />
      <div className="mt-10">
        <AboutSchool active={isActive} />
      </div>
      <div className="mt-5">
        <Enroll active={isActive} />
      </div>
      <div className="mt-5">
        <Portal active={isActive} />
      </div>

      <div className="mt-5">
        <Team active={isActive} />
      </div>
      <div className="mt-5">
        <Contact active={isActive} />
      </div>
      <div className="mt-5">
        <FAQs active={isActive} />
      </div>
    </div>
  );
};

export default Mainsections;
