import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  schoolId: 1,
  isOpenModal: false,
};

export const appReducer = createSlice({
  name: "app",
  initialState,
  reducers: {
    setSchoolId: (state, action) => {
      state.schoolId = action.payload;
    },
    setToggleModal: (state, action) => {
      state.isOpenModal = action.payload;
    },
  },
});


export const { setSchoolId, setToggleModal } = appReducer.actions;
export const reducer = appReducer.reducer;

export const selectSchoolId = (state) => state.app.schoolId;
export const selectIsOpenModal = (state) => state.app.isOpenModal;
