import { useEffect } from "react";

export const useClickOutside = (ref, handler) => {
  useEffect(() => {
    /**
     * Callback if clicked on outside of element
     */
    function handleClickOutside(event) {
      // console.log("hello", !ref.current.contains(event.target), ref);
      if (ref.current && !ref.current.contains(event.target)) {
        // console.log("here");
        handler();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, handler]);
};
