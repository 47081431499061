import cn from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import { AiFillBehanceCircle } from "react-icons/ai";
import { BsGlobe2 } from "react-icons/bs";
import { CiCircleChevDown, CiCircleChevUp } from "react-icons/ci";
import { IoLogoLinkedin } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { useGetSchoolTeamQuery } from "../../app/api";
import { selectSchoolId } from "../../app/slice";
import { Typography } from "../../components/Typography";
import { setActive } from "./slice";
const BoardMember = ({ name, image, index, position }) => {
  const icons = {
    color: "#fff",
  };

  console.log({ name });
  const isEven = (num) => num % 2 === 0;
  return (
    <div
      className={cn("flex md:w-[200px] w-full", {
        "flex-col": !isEven(index),
        "flex-col-reverse": isEven(index),
      })}
    >
      <div className=" w-full flex justify-center h-[278px] mb-3">
        <img className="w-full h-full rounded-xl" src={image} alt="" />
      </div>
      <div>
        <div className="flex flex-row items-center">
          <div className="flex-1 flex flex-wrap">
            <Typography
              gutterBottom
              className="text-white text-[16px] md:text-2xl text-start"
            >
              {name}
            </Typography>
          </div>
          <div className="flex flex-row pt-2 gap-2">
            <IoLogoLinkedin {...icons} className="cursor-pointer" />
            <AiFillBehanceCircle {...icons} className="cursor-pointer" />
            <BsGlobe2 {...icons} className="cursor-pointer" />
          </div>
        </div>
        <div>
          <Typography className="text-white text-start">{position}</Typography>
        </div>
      </div>
    </div>
  );
};

const Team = ({ props, active }) => {
  const icons = {
    color: "#fff",
  };
  const schoolId = useSelector(selectSchoolId);
  const { data } = useGetSchoolTeamQuery({ id: schoolId });
  const dispatch = useDispatch();
  return (
    <div>
      <div
        id="board"
        onClick={() => {
          dispatch(setActive(active === 3 ? null : 3));
        }}
        className="flex items-center border-b-[0.3px] border-solid border-white w-4/5 mr-auto ml-auto cursor-pointer"
      >
        <div className="w-2.5 h-2.5 rounded-full bg-[#fff] mr-4"></div>
        <div className=" flex-1">
          <Typography className="text-[#fff] text-2xl md:text-3xl">
            Team
          </Typography>
        </div>
        <div>
          {active === 3 ? (
            <CiCircleChevDown {...icons} className="w-7 h-7 mb-[0.2rem]" />
          ) : (
            <CiCircleChevUp {...icons} className="w-7 h-7 mb-[0.2rem]" />
          )}
        </div>
      </div>
      <div
        className={
          active === 3
            ? "block place-items-center bg-white/20 p-10 w-4/5 mr-auto ml-auto  mt-8 rounded-b-lg"
            : "hidden"
        }
      >
        <div
          className={cn(
            "grid md:flex mr-auto ml-auto gap-5 flex-wrap",
            data?.data > 3 ? "" : "justify-center"
          )}
        >
          {data?.data?.map?.((contact, index) => {
            return (
              <BoardMember
                key={index}
                name={contact?.fullName}
                position={contact?.position}
                image={contact?.profileImage}
                index={index}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Team;
