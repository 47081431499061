const baseUrl = process.env.REACT_APP_API_ENDPOINT_TWO;
const api_version = process.env.REACT_APP_API_VERSION;
export const API = {
  BASE_URL: baseUrl,
  GET_SCHOOLS: `/api/v${api_version}/Website/GetSchools`,
  GET_SCHOOL_PROFILE: `/api/v${api_version}/Website/GetSchoolProfile`,
  GET_ABOUT_SCHOOL: `/api/v${api_version}/Website/GetAboutUs`,
  GET_SCHOOL_CONTACT: `/api/v${api_version}/Website/Board-Contact`,
  GET_CONTACTS: `/api/v${api_version}/Website/SchoolContact`,
  GET_BOARD_TEAM: `/api/v${api_version}/Website/BoardTeam`,
  GET_SCHOOL_FAQ: `/api/v${api_version}/Website/FAQ`,
  GET_SCHOOL_TEAM: `/api/v${api_version}/Website/GetSchoolTeam`,
  GET_BOARD: `/api/v${api_version}/Website/BoardTeam`,
  AUTH_CODE: `/api/v${api_version}/Auth/Send-Code`,
  AUTH_VERIFY_CODE: `/api/v${api_version}/Auth/Verify-code`,
  Auth_CREATE_PIN: `/api/v${api_version}/Auth/Create-PIN`,
  COMPLAINT: `/api/v${api_version}/Website/SendFeedback-lasmab`,
  GET_INFO: `/api/v${api_version}/Website/Lasmab-website-info`,
  GET_USER_GUIDE: `/api/v${api_version}/Website/GetUserguide`,
  GET_ALL_GUIDE_QUESTIONS: `/api/v${api_version}/Website/UserGuide/allquestions`,
  GET_USER_GUIDE_LINK: `/api/v${api_version}/Website/GetUserguideLink`,
};
