import React from "react";
import * as yup from "yup";
import cn from "classnames";
import Slider from "react-slick";
import OtpInput from "react-otp-input";
import { toast } from "react-toastify";
import "react-phone-input-2/lib/style.css";
import "react-phone-number-input/style.css";
import { MoonLoader } from "react-spinners";
import { Typography } from "../../components";
import { Button } from "../../components/Button";
import PhoneInput from "react-phone-number-input";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { yupResolver } from "@hookform/resolvers/yup";
import { MapPinIcon } from "@heroicons/react/24/solid";
import { useDispatch, useSelector } from "react-redux";
import { phoneWithoutCountryCode } from "../../helpers";
import { useCountdown } from "../../hooks/useCountdown";
import { useForm, useFormContext } from "react-hook-form";
import { useClickOutside } from "../../hooks/useClickOutside";
import { setSchoolId, setToggleModal, selectIsOpenModal } from "../../app/slice";
import { useGetSchoolsQuery, useCreatePinMutation, useVerifyAuthCodeMutation, useLazyGetSchoolProfileQuery, useSendAuthenticationCodeMutation, } from "../../app/api";

const parent_portal = process.env.REACT_APP_PARENT;
const signSchema = yup.object().shape({
  email: yup.string().email().required("Email is required."),
  phoneNumber: yup.string().required(" Phone number is required"),
});

function redirectToWebsite(url) {
  if (window.location.href.includes("dev"))
    window.location.href = "https://parent.lasmab.org";
  else
    window.location.href = "https://dev.parent.lasmab.org";

  return;
  // if (window.location && window.location.assign) window.location.assign(url); // Modern browsers
  // else if (window.location && window.location.replace) window.location.replace(url); // Older browsers
  // else window.location.href = url; // Fallback for very old browsers
}

function Arrow(props = { style: {} }) {
  return <div {...props} style={{ ...props.style, color: "#fff", borderRadius: "50%", backgroundColor: "#671E78" }} />;
};

const Input = React.forwardRef(({ error = "", startAdornment, label = "", className = "", ...rest }, ref) => {
  return (
    <>
      <label className="mb-2 text-[#808285]">{label}</label>
      <div className="relative mt-3 mb-3">
        <div className="absolute">{startAdornment}</div>
        <input
          {...rest}
          ref={ref}
          className={cn(
            "border-b-2 border-black pb-2 w-full mb-1 ring-0 outline-none",
            className,
            {
              "pl-20": typeof startAdornment !== "undefined",
            }
          )}
        />
        {error.length !== 0 && (
          <Typography as="p" className="text-red-800 text-sm">
            {error}
          </Typography>
        )}
      </div>
    </>
  );
}
);

const Modal = ({ children, ref, isOpen }) => {
  return (
    <div
      ref={ref}
      className={`items-center justify-center fixed left-0 bottom-0 w-full h-full bg-[rgba(0,0,0,0.5)] z-[1000] select-none  ${isOpen ? "flex" : "hidden"
        }`}
    >
      {children}
    </div>
  );
};

// export function RenderSignup(props) {
//   const methods = useFormContext();
//   const formHook = useForm({ resolver: yupResolver(signSchema) });
//   const [submit, { isLoading }] = useSendAuthenticationCodeMutation();

//   const handlePhoneChange = (value) => {
//     methods.register("phoneNumber").onChange({ target: { value, name: "phoneNumber" } })
//   };
//   const handleSubmit = async (data) => {
//     try {
//       let result = await submit({
//         email: data.email, countryCode: "234",
//         phoneNumber: phoneWithoutCountryCode(data.phoneNumber).phoneNumber,
//       }).unwrap();

//       if (!result.status) return toast.error(result?.message);
//       toast.success(result?.message ?? "One time password sent!");
//       props.handleNext(data);
//     } catch (error) {
//       toast.error(JSON.stringify(error));
//     }
//   };

//   return <form onSubmit={formHook.handleSubmit(handleSubmit)} className="bg-white mr-auto ml-auto p-8 rounded-lg w-[100%] h-[100%] polygon">
//     <section className="border-b-2 border-black">
//       <label className="text-gray-600">Phone Number</label>
//       <PhoneInput
//         international
//         defaultCountry="NG"
//         withCountryCallingCode
//         rules={{ required: true }}
//         onChange={handlePhoneChange}
//         countryCallingCodeEditable={false}
//         className="!border-0 pl-2 rounded-lg w-full"
//       />
//     </section>

//     <Input
//       name="email"
//       label="Email"
//       error={formHook.formState.errors?.email?.message}
//       {...formHook.register("email", { required: true })}
//     />

//     <Button
//       isLoading={isLoading}
//       className="bg-primary mt-20 p-5 w-[100%] text-white"
//       title="submit"
//     >
//       Verify registration
//     </Button>
//   </form>;
// };

// export function RenderVerification(props) {
//   const dispatch = useDispatch();
//   const [code, setCode] = React.useState("");
//   const [showResend, setShowResend] = React.useState(false);
//   const [submit, { isLoading }] = useVerifyAuthCodeMutation();
//   const { Timer } = useCountdown({ initialHour: 0, initialMinute: 2, initialSeconds: 12, isStart: true, onComplete: () => setShowResend(true) });

//   const handleSubmit = async () => {
//     try {
//       let result = await submit({ code, phoneNumber: props.data.phoneNumber }).unwrap();

//       if (!result.status) return toast.error(result?.Message);
//       else if (result?.data?.isCreatePinRequired === false) {
//         dispatch(setToggleModal(false));
//         return redirectToWebsite(parent_portal);
//       };

//       toast.success(result?.message ?? "One time password sent!");
//       props.handleNext(result.data);
//     } catch (error) {
//       toast.error(JSON.stringify(error));
//     }
//   };

//   return <form onSubmit={handleSubmit} className="bg-white mr-auto ml-auto p-8 rounded-lg w-[100%] h-[100%] polygon">
//     <Typography className="mb-2 text-[24px] text-black text-center leading-[24px]">
//       Registration Verification
//     </Typography>

//     <Typography className="text-[14px] text-black text-center leading-[24px]">
//       Kindly enter the OTP code sent to <span className="text-primary">
//         {phoneWithoutCountryCode(props.data?.phoneNumber)?.phoneNumber}
//       </span> and <span className="text-primary">{props.data?.email}</span>
//     </Typography>

//     <OtpInput
//       isInputNum
//       value={code}
//       numInputs={6}
//       onChange={setCode}
//       containerStyle="justify-center mt-20"
//       separator={<span>&npsp;&npsp;&npsp;</span>}
//       inputStyle="!w-2/3 border-b-2 pb-2 border-black outline-none !text-black"
//     />

//     <Button title="submit" isLoading={isLoading} className="bg-primary mt-20 p-5 w-[100%] text-white"    >
//       Verify OTP Code
//     </Button>

//     <Typography className="mt-3 text-center">
//       Resend code in {showResend ? <span className="text-primary cursor-pointer">Resend</span> : <Timer />}
//     </Typography>
//   </form>;
// };

// export function RenderCreatePIN(props) {
//   const dispatch = useDispatch();
//   const [submit, { isLoading }] = useCreatePinMutation();
//   const [data, setData] = React.useState({ pin: "", confirm: "" });

//   const handleReset = () => { props.handlePrev(); };
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (data.pin !== data.confirm) return toast.error("Pin and confirm pin must be the same");

//     try {
//       let result = await submit({ ...props.data, ...data, accountType: 2 }).unwrap();
//       if (result.status === true) {
//         dispatch(setToggleModal(false));
//         toast.success(result?.message ?? "One time password sent!");
//         redirectToWebsite(parent_portal);
//       }
//     } catch (error) {
//       toast.error(JSON.stringify(error?.data?.message));
//     }
//   };

//   return <form onSubmit={handleSubmit} className="bg-white mr-auto ml-auto p-8 rounded-lg w-[100%] h-[100%] polygon">
//     <Typography className="mb-2 text-[24px] text-center leading-[24px]">
//       Create Pin
//     </Typography>

//     <Typography className="mb-16 text-[#671E78] text-[14px] text-center leading-[24px]">
//       Enter your Six digits account pin
//     </Typography>

//     <label className="block mb-4 text-black/30 text-sm">Password</label>
//     <OtpInput
//       isInputNum
//       numInputs={6}
//       value={data.pin}
//       containerStyle="justify-center"
//       onChange={pin => setData({ ...data, pin })}
//       separator={<span>&npsp;&npsp;&npsp;</span>}
//       inputStyle="!w-2/3 border-b-2 pb-2 border-black outline-none !text-black"
//     />

//     <label className="block mb-4 text-black/30 text-sm">Confirm Password</label>
//     <OtpInput
//       isInputNum
//       numInputs={6}
//       value={data.confirm}
//       containerStyle="justify-center"
//       separator={<span>&npsp;&npsp;&npsp;</span>}
//       onChange={confirm => setData({ ...data, confirm })}
//       inputStyle="!w-2/3 border-b-2 pb-2 border-black outline-none !text-black"
//     />

//     <Button title="submit" isLoading={isLoading} className="bg-primary mt-20 p-5 w-[100%] text-white"      >
//       Create Pin
//     </Button>

//     <div className="flex items-center gap-5">
//       <h4 className="text-red-600">Having problem with your account?</h4>
//       <h4 onClick={handleReset} className="font-bold cursor-pointer">
//         Reset
//       </h4>
//     </div>
//   </form>;
// };

// export function Component() {
//   const dispatch = useDispatch();
//   const [data, setData] = React.useState({});
//   const [index, setIndex] = React.useState(0);
//   const isOpenModal = useSelector(selectIsOpenModal);
//   const Element = React.useMemo(() => [RenderSignup, RenderVerification, RenderCreatePIN][index], [index]);

//   const handlePrev = () => { if (index > 0) setIndex(--index) };
//   const handleNext = (items = {}) => {
//     setData(prev => ({ ...prev, ...items }))
//   };

//   return <>
//     <section className="bg-white mx-auto p-10 rounded-lg w-full">
//       <div className="mx-auto w-full"></div>
//     </section>

//     <section className={`top-0 left-0 z-50 fixed justify-center items-center bg-black bg-opacity-50 w-screen h-screen select-none ${isOpenModal ? "flex" : "hidden"}`}>
//       <div className="relative bg-primary mt-8 p-5 md:p-10 rounded-lg w-[30rem] min-w-96">
//         <XMarkIcon onClick={() => dispatch(setToggleModal(false))} className="top-1 right-2 absolute w-8 h-8 text-white cursor-pointer" />

//         <header className="flex flex-col place-items-center mb-12">
//           <Typography className="text-white">Sign up Today</Typography>

//           <Typography className="font-bold text-white text-xl">
//             Start Your Child's Registration
//           </Typography>
//         </header>

//         <Element data={data} key={index} handlePrev={handlePrev} handleNext={handleNext} />
//       </div>
//     </section>
//   </>
// }


const SliderComponent = ({ props }) => {
  const dispatch = useDispatch();
  const divRef = React.useRef(null);

  const [onSchoolProfile] = useLazyGetSchoolProfileQuery();
  const [onCreatePin, { isLoading: isCreatingPin }] = useCreatePinMutation();
  const [onSendAuthCode, { isLoading }] = useSendAuthenticationCodeMutation();
  const [onVerifyPin, { isLoading: isVerifying }] = useVerifyAuthCodeMutation();
  const { schools } = useGetSchoolsQuery(undefined, { selectFromResult: ({ data }) => ({ schools: data?.data ?? [], }) });

  const [step, setStep] = React.useState(1);
  const [_, setIsModal] = React.useState(false);
  const [showResend, setShowResend] = React.useState(false);
  const [selectedSchool, setSelectedSchools] = React.useState(schools?.[0]);

  useClickOutside(divRef, () => setIsModal(false));
  const isOpenModal = useSelector(selectIsOpenModal);

  const { Timer } = useCountdown({ initialHour: 0, initialMinute: 2, initialSeconds: 12, isStart: step === 2, onComplete: () => setShowResend(true) });

  const {
    reset,
    register,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(signSchema) });

  const [OTP, setOTP] = React.useState("");
  const [browserToken, setBrowserToken] = React.useState("");
  const [pins, setPins] = React.useState({ pin: "", confirmPin: "" });

  React.useEffect(() => {
    if (isOpenModal === false) {
      reset(); setStep(1); setOTP("");
      setPins({ confirmPin: "", pin: "" });
    }
  }, [isOpenModal, reset]);

  const handleSchoolSelection = React.useCallback(async (school) => {
    dispatch(setSchoolId(school?.schoolid));
    try {
      setSelectedSchools(school);
      await onSchoolProfile({
        id: school?.schoolid,
      }).unwrap();
      // save the result payload to redux store.
    } catch (error) {
      console.log(error);
    }
  }, [dispatch, onSchoolProfile]);


  const onSubmit = async (data) => {
    // console.log(data);
    try {
      const result = await onSendAuthCode({
        phoneNumber: phoneWithoutCountryCode(data.phoneNumber).phoneNumber,
        email: data.email,
        countryCode: "234",
      }).unwrap();
      if (!result.status) {
        toast.error(result?.message);
        return;
      }

      localStorage.setItem("lasmabEmail", data.email);
      localStorage.setItem("lasmabPhoneNumber", data.phoneNumber);

      result.status && setStep(2);
      toast.success(result?.message ?? "One time password sent!");
    } catch (error) {
      toast.error(JSON.stringify(error));
    }
  };

  /**
   * It takes the phone number and the OTP from the form and sends it to the backend to verify the OTP
   */
  const onAuthSubmit = async (e) => {
    e.preventDefault();
    try {
      const result = await onVerifyPin({
        phoneNumber: phoneWithoutCountryCode(getValues("phoneNumber"))
          ?.phoneNumber,
        code: OTP,
      }).unwrap();

      if (!result.status)
        return toast.error(result?.Message);
      else {
        localStorage.setItem("lasmabBrowserToken", result?.data?.browserToken || "");

        if (result?.data?.isCreatePinRequired === false) {
          dispatch(setToggleModal(false));
          return redirectToWebsite(parent_portal);
        }

        setStep(3);
        setBrowserToken(result?.data?.browserToken);
        toast.success(result?.message ?? "One time password sent!");
      }

      result.status && setBrowserToken(result?.data?.browserToken);
      result.status && setStep(3);
      toast.success(result?.message ?? "One time password sent!");
    } catch (error) {
      toast.error(JSON.stringify(error));
    }
  };

  /**
   * It takes the phone number and pin from the form, and sends it to the backend to create a pin
   */
  const handleCreatePin = async (e) => {
    e.preventDefault();
    try {
      if (pins.pin !== pins.confirmPin) {
        toast.error("Pins doesn't match");
        return;
      }

      const result = await onCreatePin({
        phoneNumber: phoneWithoutCountryCode(getValues("phoneNumber"))
          ?.phoneNumber,
        pin: pins.pin,
        browserToken,
        accountType: 2,
      }).unwrap();
      // console.log(result);
      if (!result.status) return toast.error(result?.Message)
      if (result.status === true) {
        dispatch(setToggleModal(false));
        toast.success(result?.message ?? "One time password sent!");
        redirectToWebsite(parent_portal);
      }
    } catch (error) {
      toast.error(JSON.stringify(error?.data?.message));
    }
  };
  const PinClearReset = () => {
    setOTP("");
    setPins("");
    setStep(1);
  };

  const methods = useFormContext();
  let renderSignUp = (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={cn(
        "bg-white w-[100%] h-[100%] mr-auto ml-auto p-8 polygon rounded-lg"
      )}
    >
      <div className="border-[#000] border-b-2">
        <label className="text-gray-600">Phone Number</label>
        <PhoneInput
          defaultCountry="NG"
          international
          rules={{ required: true }}
          withCountryCallingCode
          countryCallingCodeEditable={false}
          ref={register}
          {...register("phoneNumber".replace(/\s/g, ""))}
          numberInputProps={{
            className: " py-4 b focus:ring-0 outline-0 focus:border-0",
          }}
          className={cn("w-full !border-0 pl-2 rounded-lg")}
          onChange={(phone) => {
            methods?.register("phoneNumber"?.replace(/\s/g, ""))?.onChange({
              target: { name: "phoneNumber", value: phone },
            });
          }}
        />
      </div>

      <Input
        name="email"
        label="Email"
        {...register("email", { required: true })}
        error={errors?.email?.message}
      />

      <Button
        isLoading={isLoading}
        className="bg-primary mt-20 p-5 w-[100%] text-white"
        title="submit"
      >
        Verify registration
      </Button>
    </form>
  );

  let renderAuthCode = (
    <form
      onSubmit={onAuthSubmit}
      className={cn(
        "bg-white w-[100%] h-[100%] mr-auto ml-auto p-8 polygon rounded-lg"
      )}
    >
      <Typography className="mb-2 text-[24px] text-black text-center leading-[24px]">
        Registration Verification
      </Typography>
      <Typography className="text-[14px] text-black text-center leading-[24px]">
        Kindly enter the OTP code sent to{" "}
        <span className="text-primary">
          {phoneWithoutCountryCode(getValues("phoneNumber"))?.phoneNumber}
        </span>{" "}
        and <span className="text-primary">{getValues("email")}</span>
      </Typography>
      <OtpInput
        onChange={(otp) => setOTP(otp)}
        numInputs={6}
        value={OTP}
        isInputNum
        containerStyle="justify-center mt-20"
        inputStyle="!w-2/3 border-b-2 pb-2 border-black outline-none !text-black"
        separator={<span>{"  "}</span>}
      />

      <Button
        isLoading={isVerifying}
        className="bg-primary mt-20 p-5 w-[100%] text-white"
        title="submit"
      >
        Verify OTP Code
      </Button>

      <Typography className="mt-3 text-center">
        Resend code in
        {showResend ? (
          <span className="text-primary cursor-pointer">Resend</span>
        ) : (
          <Timer />
        )}
      </Typography>
    </form>
  );

  let renderCreatePin = (
    <form
      onSubmit={handleCreatePin}
      className={cn(
        "bg-white w-[100%] h-[100%] mr-auto ml-auto p-8 polygon rounded-lg"
      )}
    >
      <Typography className="mb-2 text-[24px] text-center leading-[24px]">
        Create Pin
      </Typography>
      <Typography className="mb-16 text-[#671E78] text-[14px] text-center leading-[24px]">
        Enter your Six digits account pin
      </Typography>

      <label className="block mb-4 text-black/30 text-sm">Password</label>
      <OtpInput
        isInputNum
        numInputs={6}
        value={pins.pin}
        onChange={(value) => setPins({ pin: value })}
        containerStyle="justify-center"
        inputStyle="!w-2/3 border-b-2 pb-3 border-black outline-none !text-black"
        separator={<span>{"  "}</span>}
      />

      <label className="block mt-7 mb-4 text-black/30 text-sm">
        Confirm Password
      </label>
      <OtpInput
        numInputs={6}
        isInputNum
        value={pins.confirmPin}
        onChange={(value) =>
          setPins((prev) => ({ ...prev, confirmPin: value }))
        }
        containerStyle="justify-center"
        inputStyle="!w-2/3 border-b-2 pb-3 border-black outline-none !text-black"
        separator={<span>{"  "}</span>}
      />

      <Button
        isLoading={isCreatingPin}
        className="bg-primary mt-20 p-5 w-[100%] text-white"
        title="submit"
      >
        Create Pin
      </Button>

      <div className="flex items-center gap-5">
        <h4 className="text-red-600">Having problem with your account?</h4>
        <h4 onClick={PinClearReset} className="font-bold cursor-pointer">
          Reset
        </h4>
      </div>
    </form>
  );

  /* Setting the selected school to the first school in the array. */
  React.useEffect(() => {
    if (schools.length) handleSchoolSelection(schools?.[0]);
  }, [handleSchoolSelection, schools, setSelectedSchools]);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    nextArrow: <Arrow />,
    prevArrow: <Arrow style={{ left: "-30px" }} />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [buttonBgColor, setButtonBgColor] = React.useState("rgba(144, 36, 34, 0.9)");
  const handleButtonClick = (componentBgColor) => {
    setButtonBgColor(componentBgColor);
  };
  return (
    <div>
      <div className="bg-white mr-auto ml-auto p-10 rounded-lg w-[100%]">
        <div className="mr-auto ml-auto w-[100]">
          {schools.length !== 0 ? (
            <Slider
              className="flex justify-between gap-5 p-2 slide"
              {...settings}
            >
              {schools?.map((school) => {
                const bg_index = school.colorValue;
                return (
                  <>
                    <div
                      key={school}
                      className="md:mx-3 rounded-lg card-image-bg"
                    >
                      <div
                        className={cn("rounded-lg")}
                        style={{ backgroundColor: bg_index }}
                      >
                        <div
                          onClick={() => {
                            handleSchoolSelection(school);
                            handleButtonClick(bg_index);
                          }}
                          key={school?.schoolid}
                          className={cn(`rounded-lg items-center py-3`)}
                        >
                          <div className="mx-auto py-5 w-32 h-32">
                            <img
                              src={
                                school?.logo ??
                                "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQJhVKpIXOG55THsJxJgug4CN50Jm3AgS3Zkg&usqp=CAU"
                              }
                              className="rounded-lg w-full h-full object-contain"
                              alt={school?.schoolname}
                            />
                          </div>

                          <p className="mt- font-extrabold text-center text-sm text-white">
                            {school?.schoolAbrv}
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </Slider>
          ) : (
            <MoonLoader color="#671E78" className="mx-auto" />
          )}
        </div>
      </div>

      <div className="relative z-0 flex justify-between mt-3 mr-auto ml-auto p-5 md:p-10 rounded-xl w-[100%] card-image-bg">
        <div className="flex md:flex-row flex-col">
          <div
            style={{ backgroundColor: buttonBgColor }}
            className={cn(
              "rounded-xl left-0 bottom-0 absolute h-full w-full -z-10"
            )}
          />
          <div>
            <h4 className="text-white">{selectedSchool?.schoolname}</h4>
            <div className="flex items-center gap-2">
              <MapPinIcon className="w-5 h-5 text-white" />
              <Typography {...props} className="text-white">
                {selectedSchool?.schooladdress}
              </Typography>
            </div>
          </div>
        </div>
        <div className="w-20 h-20">
          <img
            src={
              selectedSchool?.logo ??
              "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQJhVKpIXOG55THsJxJgug4CN50Jm3AgS3Zkg&usqp=CAU"
            }
            className="rounded-full w-full h-full"
            alt={selectedSchool?.schoolname}
          />
        </div>
      </div>

      <Modal ref={divRef} isOpen={isOpenModal}>
        <div className="relative bg-primary mt-8 p-5 md:p-10 rounded-lg w-[30rem] min-w-[300px]">
          <XMarkIcon
            onClick={() => { setStep(1); dispatch(setToggleModal(false)); }}
            className="top-1 right-2 absolute w-8 h-8 text-white cursor-pointer"
          />
          <div className="flex flex-col place-items-center mb-[3rem]">
            <div>
              <Typography className="text-white">Sign up Today</Typography>
            </div>
            <div>
              <Typography className="font-bold text-white text-xl">
                Start Your Child's Registration
              </Typography>
            </div>
          </div>
          {step === 1
            ? renderSignUp
            : step === 2
              ? renderAuthCode
              : renderCreatePin}
        </div>
      </Modal>
      {/* <DevTool control={control} /> */}
    </div>
  );
};
export default SliderComponent;
