import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { reducer } from "../app/slice";
import { appApi } from "./api";
import { reducer as dropdownReducer } from "../layouts/dropdowns/slice";

const rootReducer = combineReducers({
  app: reducer,
  dropdown: dropdownReducer,
  [appApi.reducerPath]: appApi.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  devTools: false,
  middleware: (getDefault) => getDefault().concat(appApi.middleware),
});
