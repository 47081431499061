import React from "react";
import { Container, Typography } from "../../components";
import { HiAcademicCap, HiOutlineLightBulb } from "react-icons/hi";
import { FaHeadSideVirus } from "react-icons/fa";
import { useGetLasmabInfoQuery } from "../../app/api";
const Values = () => {
  const { data } = useGetLasmabInfoQuery();
  const lasmabData = data?.data?.values;

  return (
    <>
      <Container
        id="values"
        noGutter
        flex
        justify="center"
        className="pb-10 pt-20 bg-white"
      >
        <Container
          as="div"
          // flex="lg"
          noGutter
          justify="evenly"
          className="bg-[#671E78] p-10 lg:p-20 lg:rounded-xl md:w-[90%] lg:w-[90%] md:mr-auto md:ml-auto"
        >
          <div className="pb-3 w-full mb-5 border-b border-dashed vision">
            <div className="h-[80px] w-[80px] mb-6 bg-white/20 rounded-lg mr-auto ml-auto">
              <HiAcademicCap
                color="#E159FF"
                className="w-[60px] h-[60px] mr-auto ml-auto cardIcons"
              />
            </div>
            <Typography className="text-white mb-2 text-center">
              {lasmabData?.[0]?.valueheading}
            </Typography>
            <Typography className="text-[18px] leading-[30px] opacity-50 w-full text-white text-center">
              {lasmabData?.[0]?.valueContent}
            </Typography>
          </div>

          <div
            className=" pb-3 w-full mb-5 border-b border-dashed"
            id="mission"
          >
            <div className="h-[80px] w-[80px] mb-6 bg-white/20 rounded-lg mr-auto ml-auto">
              <HiOutlineLightBulb
                color="#5E9C08"
                className="w-[60px] h-[60px] mr-auto ml-auto cardIcons"
              />
            </div>
            <Typography className="text-white mb-2 text-center">
              {lasmabData?.[1]?.valueheading}
            </Typography>
            <Typography className="text-[18px] leading-[30px] opacity-50 w-full text-white text-center">
              {lasmabData?.[1]?.valueContent}
            </Typography>
            <Typography className="text-[18px] leading-[30px] opacity-50 w-full text-white text-center">
              {lasmabData?.[1]?.valueContent2}
            </Typography>
            <Typography className="text-[18px] leading-[30px] opacity-50 w-full text-white text-center">
              {lasmabData?.[1]?.valueContent3}
            </Typography>
          </div>
        </Container>
      </Container>
    </>
  );
};

export default Values;
