import { createSlice } from "@reduxjs/toolkit";

export const dropdownSlice = createSlice({
  name: "dropdown",
  initialState: {
    active: 0,
  },
  reducers: {
    setActive: (state, action) => {
      state.active = action.payload;
    },
  },
});

export const { setActive } = dropdownSlice.actions;
export const reducer = dropdownSlice.reducer;

export const selectActiveAccordion = (state) => state.dropdown.active; 

