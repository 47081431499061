import React, { useEffect, useState } from "react";
import { Typography } from "../Typography";
import { Link } from "react-scroll";
import { useGetLasmabInfoQuery } from "../../app/api";
import cn from "classnames";
import myPic from "../../assets/lasmabCenter.jpeg";
import { useMemo } from "react";
const StoryContainer = ({ props }) => {
  const { data } = useGetLasmabInfoQuery();
  const slideImages = useMemo(() => {
    return data?.data?.slideImages?.map((image) => image?.imagePath);
  }, [data]);

  const [backgroundImage, setBackgroundImage] = useState(myPic);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (slideImages && slideImages.length > 0) {
      const intervalId = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % slideImages.length);
      }, 5000);

      return () => clearInterval(intervalId);
    }
  }, [slideImages]);

  useEffect(() => {
    const loadImage = async () => {
      const image = new Image();
      image.onload = () => {
        setBackgroundImage(slideImages[currentIndex]);
      };
      image.src = slideImages[currentIndex];
    };

    if (slideImages && slideImages.length > 0) {
      loadImage();
    }
  }, [slideImages, currentIndex]);
  return (
    <>
      <div
        style={{
          backgroundImage: `url('${backgroundImage}')`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          width: "100%",
          backgroundPosition: "center",
          height: "100vh",
          transition: "5s",
          animationDuration: "alternate-reverse",
          animationFillMode: "forwards",
          animationIterationCount: "infinite",
          animationPlayState: "running",
          animationTimingFunction: "ease-in-out",
        }}
        className={cn("grid content-center relative z-0")}
      >
        <div className="bg-[rgba(0,0,0,0.5)] left-0 bottom-0 absolute h-full w-full -z-10" />
        <div id="home" className="ml-auto mr-auto lg:ml-[10%] p-5 md:p-0">
          <div className="mb-0 mt-[3rem]">
            <h1 className="text-white text-3xl md:text-7xl font-bold">
              Let's be part of <br /> your story
            </h1>
          </div>
          <div className="mt-5">
            <Typography as="p" className="text-white mb-5">
              12,000+ people already joined the LASMAB community <br /> Get
              started today
            </Typography>
          </div>
          <div>
            <Link
              to="enrollment"
              spy={true}
              smooth={true}
              offset={50}
              duration={500}
              className="p-5 bg-white font-extrabold text-primary rounded-lg cursor-pointer"
            >
              Enroll your child
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

// const BackgroundContainer = ({ src, ...rest }) => {
//   return (

//   );
// };
export default StoryContainer;
