import React, { useState, useEffect, useRef } from "react";
import cn from "classnames";
import { useGetLasmabInfoQuery } from "../../app/api";
import { MoonLoader } from "react-spinners";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
} from "@material-tailwind/react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { Modal } from "@mui/material";
export const SlideNews = () => {
  const { newsData } = useGetLasmabInfoQuery(undefined, {
    selectFromResult: ({ data, ...values }) => ({
      ...values,
      newsData: data?.data?.lasmabNews,
    }),
  });
  const [showModal, setShowModal] = useState(false);
  const [selectedNews, setSelectedNews] = useState(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const boardListRef = useRef(null);

  const handleScroll = (direction) => {
    const scrollStep = boardListRef.current.clientWidth;
    const scrollOffset = direction === "right" ? scrollStep : -scrollStep;
    boardListRef.current.scrollBy({ left: scrollOffset, behavior: "smooth" });
  };

  const handleScrollUpdate = () => {
    setScrollPosition(boardListRef?.current?.scrollLeft);
  };

  useEffect(() => {
    boardListRef?.current?.addEventListener("scroll", handleScrollUpdate);
    return () => {
      boardListRef?.current?.removeEventListener("scroll", handleScrollUpdate);
    };
  }, [boardListRef, handleScrollUpdate]);
  const handleSeeMore = (selectedItem) => {
    setSelectedNews(selectedItem);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const truncateString = (str, maxLength) => {
    if (str?.length <= maxLength) {
      return str;
    }
    return str.slice(0, maxLength) + "...";
  };

  return (
    <>
      <div className="bg-white py-[30px]">
        <div className="mb-5 flex flex-col justify-center items-center">
          <Typography className="text-primary text-center">News</Typography>
          <Typography className="text-[#000000] text-[17px] md:font-[600] md:text-[20px] text-center">
            Exploring the latest Updates, Innovations and Breakthroughs <br />{" "}
            in Lagos Anglican Schools
          </Typography>
        </div>
        <div
          id="news"
          className="h-full bg-white px-2 lg:px-5 overflow-hidden w-full flex justify-center items-center relative"
        >
          {typeof newsData !== "undefined" ? (
            <>
              <div
                onClick={() => handleScroll("left")}
                className={
                  boardListRef?.current?.scrollLeft
                    ? "hidden md:flex"
                    : "hidden"
                }
              >
                <button className="bg-primary p-1 lg:p-3 rounded-full">
                  <NavigateBeforeIcon className="text-white font-bold md:w-3 w-2 h-2 md:h-3" />
                </button>
              </div>
              <div
                ref={boardListRef}
                className="flex overflow-auto max-w-full lg:max-w-[70%] gap-5 px-5 py-4 lg:mx-[20px] snap-x scrollbar-hide"
              >
                {newsData.map((item, _index) => (
                  <>
                    <div id="schools" key={_index} className="">
                      <Card className="md:w-[24rem] w-[300px] shadow-lg h-full overflow-hidden">
                        <CardHeader
                          floated={false}
                          shadow={false}
                          color="transparent"
                          className="m-0 rounded-none"
                        >
                          <img
                            src={item?.image}
                            className="h-[200px] w-full object-cover"
                          />
                        </CardHeader>
                        <CardBody>
                          <Typography
                            variant="h4"
                            className="text-[18px] md:text-[20px]"
                            color="blue-gray"
                          >
                            {item?.newstitle}
                          </Typography>
                          <Typography
                            variant="lead"
                            color="gray"
                            className="md:mt-3 mt-1 text-[16px] md:text-[18px] font-normal"
                          >
                            {truncateString(item.content, 100)}
                          </Typography>
                        </CardBody>
                        <CardFooter className="flex items-center justify-between">
                          <p
                            className="text-primary cursor-pointer"
                            onClick={() => handleSeeMore(item)}
                          >
                            see more
                          </p>
                        </CardFooter>
                      </Card>
                    </div>
                  </>
                ))}
              </div>
              <div
                onClick={() => handleScroll("right")}
                className={
                  boardListRef?.current?.scrollLeft <
                  boardListRef?.current?.scrollWidth -
                    boardListRef?.current?.clientWidth
                    ? "hidden md:flex"
                    : "hidden"
                }
              >
                <button className="bg-primary p-1 lg:p-3 rounded-full">
                  <NavigateNextIcon className="text-white font-bold md:w-3 w-2 h-2 md:h-3" />
                </button>
              </div>
            </>
          ) : (
            <MoonLoader color="#671E78" className="mx-auto" />
          )}
          {showModal && selectedNews && (
            <Modal
              open={showModal}
              onClose={closeModal}
              className="flex items-center justify-center"
            >
              <div className="bg-white w-[600px] h-[600px] overflow-auto p-6 rounded-lg">
                <img
                  src={selectedNews?.image}
                  className="h-[400px] w-full object-contain"
                />
                <h2 className="text-xl font-bold">{selectedNews?.newstitle}</h2>
                <p className="text-gray-500">{selectedNews?.content}</p>
                <button className="text-primary mt-4" onClick={closeModal}>
                  Close
                </button>
              </div>
            </Modal>
          )}
        </div>
        <div className="flex justify-center md:hidden gap-3">
          <div
            style={{
              display: boardListRef?.current?.scrollLeft ? "block" : "none",
            }}
            onClick={() => handleScroll("left")}
            className="hidden md:flex"
          >
            <button className="bg-primary p-1 lg:p-3 rounded-full">
              <NavigateBeforeIcon className="text-white font-bold md:w-3 w-2 h-2 md:h-3" />
            </button>
          </div>
          <div
            onClick={() => handleScroll("right")}
            style={{
              display:
                boardListRef?.current?.scrollLeft <
                boardListRef?.current?.scrollWidth -
                  boardListRef?.current?.clientWidth
                  ? "block"
                  : "none",
            }}
          >
            <button className="bg-primary p-1 lg:p-3 rounded-full">
              <NavigateNextIcon className="text-white font-bold md:w-3 w-2 h-2 md:h-3" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
