import React, { useState } from "react";
import { IoIosArrowDropdown } from "react-icons/io";
import { Typography } from "../../components/Typography";
import { Button } from "../../components";
import { useDispatch } from "react-redux";
import { setActive } from "./slice";
import { CiCircleChevDown, CiCircleChevUp } from "react-icons/ci";
const parent_login = process.env.REACT_APP_PARENT;
const student_login = process.env.REACT_APP_ADMIN;
const Portal = ({ props, active }) => {
  const [isLoading, setIsLoading] = useState(false);

  const icons = {
    color: "#fff",
  };

  const handleClick = () => {
    setIsLoading(true);
    const interval = setInterval(() => {
      // Do some async operation
      clearInterval(interval);
      setIsLoading(false);
    }, 1000);
  };
  const dispatch = useDispatch();

  return (
    <div>
      <div
        onClick={() => {
          dispatch(setActive(active === 2 ? null : 2));
        }}
        className="flex items-center border-b-[0.3px] border-solid border-white w-4/5 mr-auto ml-auto cursor-pointer"
      >
        <div className="w-2.5 h-2.5 rounded-full bg-[#fff] mr-4"></div>
        <div className=" flex-1">
          <Typography className="text-[#fff] text-2xl md:text-3xl">
            Portal
          </Typography>
        </div>
        <div>
          {active === 2 ? (
            <CiCircleChevDown {...icons} className="w-7 h-7 mb-[0.2rem]" />
          ) : (
            <CiCircleChevUp {...icons} className="w-7 h-7 mb-[0.2rem]" />
          )}
        </div>
      </div>
      <div
        className={
          active === 2
            ? "md:flex flex-col items-center justify-evenly p-10 md:w-4/5 w-[100%]  mr-auto ml-auto mt-5  md:mt-8 md:flex-row"
            : "hidden"
        }
      >
        <div
          onClick={handleClick}
          className="mb-5 md:mb-0 w-72 h-28 rounded-lg s-portal relative flex items-center justify-center cursor-pointer"
        >
          <div className="bg-primary/60 h-full w-full absolute rounded-lg"></div>
          <a
            href="https://parent.lasmab.org"
            className="text-white relative decoration-transparent z-10"
          >
            Parent Portal
          </a>
        </div>
        <div
          {...props}
          className="mb-5 md:mb-0 w-72 h-28 rounded-lg p-portal relative flex items-center justify-center cursor-pointer"
        >
          <div className="bg-primary/60 h-full w-full absolute rounded-lg"></div>
          <a
            href="https://student.lasmab.org"
            className="text-white relative decoration-transparent z-10"
          >
            Student Portal
          </a>
        </div>
      </div>
    </div>
  );
};

export default Portal;
