import React, { useRef } from "react";
import { useForm } from "react-hook-form";
import { Typography } from "../../components";
import { Button } from "../../components";
import cn from "classnames";
import { useCreateComplaintMutation } from "../../app/api";
import { toast } from "react-toastify";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = yup.object().shape({
  fullName: yup.string().required("Full name is required."),
  email: yup.string().email().required("Email is required."),
  phoneNumber: yup.string().required(" Phone number is required"),
  message: yup.string().required("Your message is required"),
});

const Questions = ({ props }) => {
  const form = useRef(null);
  const [onSubmitComplaint, { isLoading }] = useCreateComplaintMutation();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ resolver: yupResolver(schema) });

  const onSubmit = async (data) => {
    try {
      const result = await onSubmitComplaint({ ...data }).unwrap();

      !result?.status && toast.error(result?.message);

      result?.status && toast.success(result?.message);
      form.current.reset();
    } catch (error) {
      toast.error(JSON.stringify(error));
    }
  };

  return (
    <div className="bg-[#671E78] p-5 md:p-10 lg:flex lg:flex-row place-items-center justify-items-center justify-center">
      <div className="mb-5 lg:mb-0 grid place-content-center">
        <div>
          <Typography
            {...props}
            className="text-white text-[24] lg:text-[60px] md:text-[40px] font-bold"
          >
            Do you have any <br className="hidden lg:flex lg:flex-row" />{" "}
            questions?
          </Typography>
        </div>
        <div>
          <Typography className="text-white text-sm md:text-[24px] mt-5 md:mt-3 text-center lg:text-start">
            fill our feedback form
          </Typography>
        </div>
      </div>
      <div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          {...props}
          className={cn(
            "bg-white w-[100%] md:w-[90%] h-[100%] mr-auto ml-auto p-8 polygon"
          )}
          ref={form}
        >
          <div className="flex flex-col">
            <label className="text-[#808285] block mb-2">NAME:</label>
            <input
              type="text"
              placeholder="Full Name"
              {...props}
              className="outline-none w-[100%] border-b-2 border-[#1B1C31] border-solid"
              {...register("fullName", { required: true })}
              aria-invalid={errors.fullName ? "true" : "false"}
            />

            {errors.fullName?.type === "required" && (
              <p role="alert" {...props} className="text-red-600">
                Your full name is required
              </p>
            )}
          </div>
          <div className="flex md:flex-row flex-col md:mt-5 md:gap-7 w-full">
            <div className="w-full mt-5 md:mt-0">
              <label className="text-[#808285] block mb-2">EMAIL</label>

              <input
                {...props}
                placeholder="Your email address"
                className="outline-none border-b-2 border-[#1B1C31] border-solid w-full"
                {...register("email", {
                  required: "Email Address is required",
                })}
                aria-invalid={errors.mail ? "true" : "false"}
              />
              {errors.email && (
                <p role="alert" {...props} className="text-red-600">
                  {errors.email?.message}
                </p>
              )}
            </div>
            <div className="w-full mt-5 md:mt-0">
              <label className="text-[#808285] block mb-2">PHONE</label>

              <input
                {...props}
                type="tel"
                placeholder="Your phone number"
                className="outline-none border-b-2 border-[#1B1C31] border-solid w-full"
                {...register("phoneNumber", {
                  required: "Your phone number is required",
                })}
                aria-invalid={errors.phoneNumber ? "true" : "false"}
              />
              {errors.phoneNumber && (
                <p role="alert" {...props} className="text-red-600">
                  {errors.phoneNumber?.message}
                </p>
              )}
            </div>
          </div>

          <div className="mt-5">
            <label className="text-[#808285] block mb-2">FEEDBACK:</label>
            <textarea
              placeholder="your feedback here"
              {...register("message", {
                required: "Feedback message is required",
              })}
              aria-invalid={errors.feedback ? "true" : "false"}
              className="outline-none  border-b-2 border-[#1B1C31] border-solid w-full"
            ></textarea>

            {errors.message && (
              <p role="alert" {...props} className="text-red-600">
                {errors.message?.message}
              </p>
            )}
          </div>
          <Button
            {...props}
            isLoading={isLoading}
            className="text-white bg-primary p-5 w-[100%] mt-10 md:mt-20"
            title="submit"
          >
            send message
          </Button>
        </form>
      </div>
    </div>
  );
};

export default Questions;
