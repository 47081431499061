import React, { useEffect } from "react";
import {
  Accordion,
  AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import { CiCircleChevDown, CiCircleChevUp } from "react-icons/ci";
import { useDispatch, useSelector } from "react-redux";
import { useGetFaqsQuery } from "../../app/api";
import { selectSchoolId } from "../../app/slice";
import { Typography } from "../../components/Typography";
import { setActive } from "./slice";
function FAQs({ active }) {
  const schoolId = useSelector(selectSchoolId);
  const icons = {
    color: "#fff",
  };
  const dispatch = useDispatch();
  const { data, isLoading, refetch } = useGetFaqsQuery({ id: schoolId });
  useEffect(() => {
    refetch();
  }, [data]);
  return (
    <div>
      <div
        onClick={() => {
          dispatch(setActive(active === 5 ? null : 5));
        }}
        className="flex items-center border-b-[0.3px] border-solid border-white cursor-pointer w-4/5 mr-auto ml-auto"
      >
        <div className="w-2.5 h-2.5 rounded-full bg-[#fff] mr-4"></div>
        <div className=" flex-1">
          <Typography className="text-[#fff] text-2xl md:text-3xl">
            FAQs
          </Typography>
        </div>
        <div>
          {active === 5 ? (
            <CiCircleChevDown {...icons} className="w-7 h-7 mb-[0.2rem]" />
          ) : (
            <CiCircleChevUp {...icons} className="w-7 h-7 mb-[0.2rem]" />
          )}
        </div>
      </div>
      <div
        className={
          active === 5
            ? "block place-items-center bg-white/20 p-10 w-4/5 mr-auto ml-auto  mt-8 rounded-b-lg"
            : "hidden"
        }
      >
        <Accordion>
          {data?.data?.map?.((item) => (
            <AccordionItem key={item}>
              <AccordionItemHeading>
                <AccordionItemButton>{item.question}</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <Typography className="text-lg text-[#fff]">
                  {item.answer}
                </Typography>
              </AccordionItemPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </div>
  );
}
export default FAQs;
