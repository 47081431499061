/**
 * It takes a phone number as a string, checks if it's 11 digits long,
 * and if it is, removes the first character and it returns the
 * phone number with the country code appended to it
 * @param {string} phoneNumber - This is the phone number that the user inputs.
 * @returns An object with a phoneNumber property.
 */
// export const appendCountryCode = (phoneNumber = "") => {
//   let phone;
//   // console.log(phoneNumber.trim().length);
//   if (phoneNumber.trim().length === 11) {
//     phone = phoneNumber.trim().slice(1);
//   } 
//   return { phoneNumber: `+234${phone}` };
// };


export const phoneWithoutCountryCode = (phoneNumber = "") => {
  let isNigerianPhone = phoneNumber?.includes?.("234");

  let phoneNumberWithOutCountryCode = phoneNumber?.split?.("+234")?.[1]?.replace?.(/\s/g, "");
  // let countryCode = phoneNumber.split('234')
  let formatNigerianPhone =
    phoneNumberWithOutCountryCode?.length === 11
      ? phoneNumberWithOutCountryCode
      : `0${phoneNumberWithOutCountryCode}`;

  let phone = isNigerianPhone ? formatNigerianPhone?.replace?.(/\s/g, "") : phoneNumber?.replace?.(/\s/g, "");

  let result = {
    phoneNumber: phone,
    raw: phoneNumberWithOutCountryCode?.replace?.(/\s/g, ""),
  }
console.log(result)
  return result;
};
