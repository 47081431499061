import React from "react";
import StoryContainer from "../../components/storyPath/StoryContainer";

const Home = () => {
  return (
    <div>
      <StoryContainer />
    </div>
  );
};

export default Home;
