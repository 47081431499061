import React from "react";
import { CiCircleChevDown, CiCircleChevUp } from "react-icons/ci";
import { useDispatch, useSelector } from "react-redux";
import { useGetAboutSchoolQuery } from "../../app/api";
import { selectSchoolId } from "../../app/slice";
import { Typography } from "../../components/Typography";
import { setActive } from "./slice";

function Example({ active }) {
  const icons = {
    color: "#fff",
  };

  const schoolId = useSelector(selectSchoolId);
  const dispatch = useDispatch();

  const { data } = useGetAboutSchoolQuery(
    { id: schoolId },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  return (
    <div>
      <div
        onClick={() => {
          dispatch(setActive(active === 0 ? null : 0));
        }}
        className="flex items-center border-b-[0.3px] border-solid border-white w-4/5 mr-auto ml-auto"
      >
        <div className="w-2.5 h-2.5 rounded-full bg-[#fff]  mr-4"></div>
        <div className=" flex-1">
          <Typography className="text-[#fff] text-2xl md:text-3xl">
            About the school
          </Typography>
        </div>
        <div>
          {active === 0 ? (
            <CiCircleChevDown {...icons} className="w-7 h-7 mb-[0.2rem]" />
          ) : (
            <CiCircleChevUp {...icons} className="w-7 h-7 mb-[0.2rem]" />
          )}
        </div>
      </div>
      <div
        className={
          active === 0
            ? "block place-items-center bg-white/20 p-10 w-4/5 mr-auto ml-auto overflow-auto mt-8 rounded-b-lg max-h-[300px]"
            : "hidden"
        }
      >
        <Typography className="text-lg text-[#fff]">
          {data?.data?.about_Us}
        </Typography>
      </div>
    </div>
  );
}
export default Example;
