import React, { useState } from "react";
import { HiMenu } from "react-icons/hi";
import { useDispatch } from "react-redux";
import { setToggleModal } from "../../app/slice";
import { Button } from "../Button";
import { Typography } from "../Typography";
import logo from "../../assets/lasmab-logo.png";
import { Link } from "react-scroll";
import { useGetLasmabInfoQuery } from "../../app/api";
import ClipLoader from "react-spinners/ClipLoader";
const admin_login = process.env.REACT_ADMIN;
const Header = ({ props }) => {
  const [isNavExpanded, isSetNavExpanded] = useState(false);
  const menu = {
    color: "#fff",
  };
  const { data, error } = useGetLasmabInfoQuery();

  const lasmabData = data?.data?.lasmabInfo;

  const name = lasmabData?.name;
  const dispatch = useDispatch();
  return (
    <div>
      <div
        id="blur-back"
        className="flex flex-row items-center container h-[100px] md:h-[128px]"
      >
        <div className="mt-5 md:mt-0 flex flex-row md:flex-col md:flex lg:flex lg:flex-row">
          <div className="h-[70px] w-[70px] flex justify-center items-center p-2 mr-3 rounded-full">
            {lasmabData?.logoPath === undefined ? (
              <ClipLoader
                color="#671E78"
                size={20}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            ) : (
              <img
                className="w-full h-full"
                src={lasmabData?.logoPath}
                alt="this is the logo of lasmab "
              />
            )}
          </div>
          <Typography
            {...props}
            className="text-white font-semibold mt-2 md:hidden lg:block max-w-[250px]"
          >
            {name}
          </Typography>
        </div>
        <div className="navigation items-center">
          <ul className="flex flex-row content-between items-center listed text-white pt-3 w-[80%]h-[100%] cursor-pointer">
            <li>
              <Link
                to="home"
                spy={true}
                smooth={true}
                duration={500}
                activeClass="active"
                offset={-2000}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="values"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
              >
                Our Vision
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="schools"
                spy={true}
                smooth={true}
                offset={50}
                duration={500}
              >
                Our Schools
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="board"
                spy={true}
                smooth={true}
                offset={130}
                duration={500}
              >
                Board
              </Link>
            </li>
            <li>
              <Link
                to="news"
                spy={true}
                activeClass="active"
                smooth={true}
                offset={-230}
                duration={500}
              >
                News
              </Link>
            </li>
            <li>
              <Link
                to="contact"
                spy={true}
                activeClass="active"
                smooth={true}
                offset={-60}
                duration={500}
              >
                Contact
              </Link>
            </li>
            <li className="flex items-center">
              <a href="https://admin.lasmab.org/">
                <div className="px-[30px] py-[10px] bg-white rounded-lg cursor-pointer">
                  <p className=" text-primary decoration-transparent">Login</p>
                </div>
              </a>
            </li>
          </ul>
        </div>
        <div className="hamburger lg:mt-0 mt-7">
          <HiMenu
            onClick={() => {
              isSetNavExpanded(!isNavExpanded);
            }}
            {...menu}
            className="w-10 h-8"
          />
        </div>
      </div>

      <div
        className={
          isNavExpanded
            ? "block place-items-center bg-[#521760] p-10 w-[50%] h-[100vh] fixed z-[1100] md:hidden"
            : "hidden"
        }
      >
        <ul className="flex flex-col font-semibold content-between listed text-white mr-auto ml-auto">
          <li className="mb-5 w-fit cursor-pointer select-none">
            <Link
              to="home"
              spy={true}
              smooth={true}
              duration={500}
              activeClass="active"
              onSetActive={() => isSetNavExpanded(false)}
              offset={-2000}
            >
              Home
            </Link>
          </li>
          <li className="mb-5">
            <Link
              to="values"
              spy={true}
              smooth={true}
              activeClass="active"
              duration={500}
              onSetActive={() => isSetNavExpanded(false)}
              offset={1}
            >
              Our Vision
            </Link>
          </li>
          <li className="mb-5">
            <Link
              to="schools"
              spy={true}
              smooth={true}
              activeClass="active"
              onSetActive={() => isSetNavExpanded(false)}
              duration={500}
              offset={300}
            >
              Our Schools
            </Link>
          </li>
          <li className="mb-5">
            <Link
              to="board"
              spy={true}
              smooth={true}
              activeClass="active"
              onSetActive={() => isSetNavExpanded(false)}
              duration={500}
              offset={130}
            >
              Board
            </Link>
          </li>
          <li className="mb-5">
            <Link
              to="news"
              spy={true}
              activeClass="active"
              smooth={true}
              offset={-100}
              duration={500}
              onSetActive={() => isSetNavExpanded(false)}
            >
              News
            </Link>
          </li>
          <li className="mb-5">
            <Link
              to="contact"
              spy={true}
              smooth={true}
              duration={500}
              activeClass="active"
              onSetActive={() => isSetNavExpanded(false)}
            >
              Contact
            </Link>
          </li>
          <li onClick={() => isSetNavExpanded(false)}>
            <a
              href="https://admin.lasmab.org/"
              className="text-white decoration-transparent"
            >
              Login
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Header;
