import React, { useEffect, useRef, useState } from "react";
import { IoLogoLinkedin } from "react-icons/io";
import { AiFillBehanceCircle } from "react-icons/ai";
import { BsGlobe2 } from "react-icons/bs";
import { Typography } from "../../components/Typography";
import { useGetBoardQuery } from "../../app/api";
// import { MoonLoader } from "react-spinners";
import ClipLoader from "react-spinners/ClipLoader";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
const BoardMember = ({ name, image, position }) => {
  return (
    <div className=" snap-start">
      <div className="w-[278px] h-[278px] rounded-xl">
        <img
          className="w-full h-full object-cover rounded-xl"
          src={
            image ??
            "https://www.swansea.ac.uk/international-students/my-country/nigeria/Oluwafunmibi.jpg"
          }
          alt=""
        />
      </div>
      <div className="flex flex-row w-[278px] mt-4">
        <div id="contact" className="flex-1">
          <Typography
            gutterBottom
            className="text-[#000000] text-2xl text-start"
          >
            {name}
          </Typography>
        </div>
        <div className="flex flex-row pt-2 gap-3">
          <IoLogoLinkedin className="cursor-pointer" />
          <AiFillBehanceCircle className="cursor-pointer" />
          <BsGlobe2 className="cursor-pointer" />
        </div>
      </div>
      <div>
        <Typography className="text-[#000000] text-start">
          {position}
        </Typography>
      </div>
    </div>
  );
};

const BoardMembers = ({ props }) => {
  const { data, isLoading } = useGetBoardQuery();
  const [scrollPosition, setScrollPosition] = useState(0);
  const boardListRef = useRef(null);

  const handleScroll = (direction) => {
    const scrollStep = boardListRef?.current?.clientWidth;
    const scrollOffset = direction === "right" ? scrollStep : -scrollStep;
    boardListRef?.current?.scrollBy({ left: scrollOffset, behavior: "smooth" });
  };

  const handleScrollUpdate = () => {
    setScrollPosition(boardListRef.current.scrollLeft);
  };

  useEffect(() => {
    // Add the scroll event listener on mount
    boardListRef?.current?.addEventListener("scroll", handleScrollUpdate);

    // Remove the scroll event listener on unmount
    return () => {
      boardListRef?.current?.removeEventListener("scroll", handleScrollUpdate);
    };
  }, []);
  return (
    <div className="py-12 px-[10px] mb: lg:ml-7">
      <div className="mb-10">
        <Typography className="text-primary text-center">
          THE PROPRIETORS AND BOARD MEMBERS
        </Typography>
        <Typography className="text-[#000000] text-2xl text-center">
          Meet Our Awesome Proprietors And Board Members
        </Typography>
      </div>
      {isLoading && (
        <div className="flex justify-center">
          <ClipLoader
            color="#671E78"
            size={30}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
      {data?.data?.length > 0 && (
        <div className="md:flex justify-center items-center relative">
          <div className="hidden md:flex">
            <div
              // style={{
              //   display: scrollPosition > 0 ? "block" : "none",
              // }}
              onClick={() => handleScroll("left")}
            >
              <button className="bg-primary p-1 lg:p-3 rounded-full">
                <NavigateBeforeIcon className="text-white font-bold w-3 h-3" />
              </button>
            </div>
          </div>
          <div
            className="board-members-container mb-5 md:mb-0 flex overflow-auto max-w-full lg:max-w-[60%] gap-5 md:px-5 mx-[10px] md:mx-[20px] snap-x scrollbar-hide"
            ref={boardListRef}
          >
            {data?.data?.map((board, i) => (
              <BoardMember
                key={i}
                name={board?.fullName}
                image={board?.profileImage}
                position={board?.position}
              />
            ))}
          </div>
          <div className="md:flex hidden">
            <div
              onClick={() => handleScroll("right")}
              // className={
              //   boardListRef?.current?.scrollLeft <
              //   boardListRef?.current?.scrollWidth -
              //     boardListRef?.current?.clientWidth
              //     ? "flex"
              //     : "hidden"
              // }
            >
              <button className="bg-primary p-1 lg:p-3 rounded-full">
                <NavigateNextIcon className="text-white font-bold w-3 h-3" />
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="flex justify-center md:hidden gap-3">
        <div
          // style={{
          //   display: boardListRef?.current?.scrollLeft ? "block" : "none",
          // }}
          onClick={() => handleScroll("left")}
          // className="hidden md:flex"
        >
          <button className="bg-primary p-1 lg:p-3 rounded-full">
            <NavigateBeforeIcon className="text-white font-bold md:w-3 w-2 h-2 md:h-3" />
          </button>
        </div>
        <div
          onClick={() => handleScroll("right")}
          // style={{
          //   display:
          //     boardListRef?.current?.scrollLeft <
          //     boardListRef?.current?.scrollWidth -
          //       boardListRef?.current?.clientWidth
          //       ? "block"
          //       : "none",
          // }}
        >
          <button className="bg-primary p-1 lg:p-3 rounded-full">
            <NavigateNextIcon className="text-white font-bold md:w-3 w-2 h-2 md:h-3" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default BoardMembers;
